import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

const Refund = () => {
    return (
        <div>
            <Link to='/' className="scroll-smooth flex-shrink-0 flex shadow-md shadow-gray-400 h-16  justify-center items-center">
                <img className="w-10 rounded-full" src="logo.jpg" alt="Logo" />
                <h2 className='font-semibold text-blue-800'>Model Verse</h2>
            </Link>
            <div className='lg:mx-40 mx-5 lg:my-20 py-5'>
                <h1 className='text-5xl underline font-semibold mb-10 tracking-wider'>Refund</h1>
                <p className='text-justify tracking-wide'>
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Refund Policy: Refund Policy for ModelVerse
                    </h1>

                    At ModelVerse, we strive to provide high-quality services to our customers. However, we understand that there may be situations where you may need to request a refund. This Refund Policy outlines the circumstances under which refunds may be issued for our services.

                    <h1 className='text-2xl font-semibold underline my-5'>
                        Eligibility for Refund:
                    </h1>

                    Refunds may be issued under the following circumstances:
                    Service Disruption: If our services are unavailable or disrupted due to technical issues or other unforeseen circumstances beyond our control, and we are unable to resolve the issue within a reasonable timeframe.
                    Non-Delivery: If you have paid for a service but did not receive the expected outcome, such as AI fashion models not generated or images not processed as described.
                    Dissatisfaction: If you are dissatisfied with the quality of our services and can provide valid reasons for your dissatisfaction.
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Refund Requests:

                    </h1>
                    To request a refund, you must contact us within [ 3 days] of the service delivery or occurrence of the issue.
                    You may be required to provide relevant details, such as your order number, description of the issue, and any supporting documentation or evidence.
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Refund Process:

                    </h1>
                    Once we receive your refund request and verify the eligibility criteria, we will initiate the refund process.
                    Refunds will be issued to the original payment method used for the purchase.
                    Depending on your payment provider's policies, it may take some time for the refund to be reflected in your account.
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Non-Refundable Items:

                    </h1>

                    Certain services or products may be non-refundable, including but not limited to:
                    Customized or personalized services tailored to your specific requirements.
                    Services that have already been completed or partially fulfilled.
                    Services provided as part of promotional offers or discounts, unless explicitly stated otherwise.
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Dispute Resolution:
                    </h1>

                    If you are not satisfied with the outcome of your refund request, you may contact our customer support team to escalate the matter for further review and resolution.
                    Changes to Refund Policy:

                    We reserve the right to update or modify this Refund Policy at any time. Any changes will be effective immediately upon posting on our website. We encourage you to review this Refund Policy periodically for updates.
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Contact Us:
                    </h1>

                    If you have any questions, concerns, or requests regarding this Refund Policy or your eligibility for a refund, please contact us at [contact@modelverse.in].
                    By using our services, you agree to abide by the terms and conditions outlined in this Refund Policy.
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default Refund
