import React from "react";

function Card({ cardcontent }) {
  return (
    <div className="flex flex-wrap scroll-smooth">
      {cardcontent.map((data, index) => (
        <div className="w-1/3 group before:hover:scale-95 before:hover:h-72 before:hover:w-80 before:hover:h-44 before:hover:rounded-b-2xl before:transition-all before:duration-500 before:content-[''] before:w-80 before:h-24 before:rounded-t-2xl before:bg-gradient-to-bl from-purple-600 via-pink-100 to-white  before:absolute before:top-0 h-72 relative flex flex-col items-center justify-center gap-2 text-center rounded-2xl overflow-hidden">
          <img
            src={data.img}
            className="w-28 h-28 bg-blue-700 mt-8 rounded-full border-4 border-slate-50 z-10 group-hover:scale-150 group-hover:-translate-x-24  group-hover:-translate-y-20 transition-all duration-500"
          ></img>
          <div className="z-10  group-hover:-translate-y-10 transition-all duration-500">
            <span className="text-2xl font-semibold">{data.title}</span>
            <p>{data.content}</p>
          </div>
          <a
            className="border-2 border-cyan-500 px-4 py-1 text-neutral-800 font-semibold rounded-md z-10 hover:scale-125 transition-all duration-500 hover:bg-cyan-500"
            href="#"
          >
            Start Now
          </a>
        </div>
      ))}
    </div>
  );
}

export default Card;
