import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Contact() {
  const { ref, inView } = useInView({ triggerOnce: true });

  const variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: inView ? 1 : 0,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    show: {
      opacity: 1,
      y: inView ? 0 : 100,
      transition: {
        duration: 0.8,
      },
    },
  };

  const [userData, setuserData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setuserData({ ...userData, [name]: value });
  };

  // connect with Database
  const submitData = async (event) => {
    event.preventDefault();
    const { firstName, lastName, phone, email, message } = userData;

    if (firstName && lastName && phone && email && message) {
      const res = await fetch("https://contact.modelverse.in/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName,
          lastName,
          phone,
          email,
          message,
        }),
      });

      if (res.status === 200) {
        setuserData({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          message: "",
        });
        // alert("Data Stored");
        // toast("Wow so easy !");
        toast.success("Done! Our team will contact you soon.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // toast.success("Our team will contact you soon");
      } else {
        toast.error("Invalid Credentials", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      // alert("Unfilled");
      toast.error("Invalid Credentials", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <div className="scroll-smooth">
      {/* Contact Us */}
      <motion.div
        ref={ref}
        variants={variants}
        initial="hidden"
        animate="show"
        className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
      >
        <div className="max-w-2xl lg:max-w-5xl mx-auto">
          <div className="text-center">
            <motion.h1
              variants={item}
              className="lg:text-3xl text-xl font-semibold title-font lg:mb-10 mb-5  bg-gradient-to-r from-[#29B5D9BF] via-[#8386FF] to-[#B48FFF] bg-clip-text text-transparent "
            >
              Contact us
            </motion.h1>
            <motion.p
              variants={item}
              className="lg:w-[60%] mx-auto lg:text-5xl text-[23px] font-bold title-font mb-5 text-[#222121]"
            >
              We'd love to talk about how we can help you.
            </motion.p>
          </div>
          <div className="mt-12 grid items-center lg:grid-cols-2 gap-6 lg:gap-16">
            {/* Card */}
            <motion.div
              variants={item}
              className="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-8 dark:border-gray-700"
            >
              <h2 className="mb-8 text-xl font-semibold ">Fill in the form</h2>

              <form method="POST" className="md:col-span-8 p-">
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2 mb-6 md:mb-0 px-1">
                    <input
                      className="appearance-none bg-gray-100 block w-full text-gray-700 font-semibold rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none "
                      id="grid-first-name"
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={userData.firstName}
                      onChange={postUserData}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-1 mb-6 md:mb-0">
                    <input
                      className="appearance-none bg-gray-100 block w-full text-gray-700 font-semibold rounded-lg py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                      id="grid-last-name"
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={userData.lastName}
                      onChange={postUserData}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap mb-3">
                  <div className="w-full md:w-1/2 mb-6 md:mb-0 px-1">
                    <input
                      className="appearance-none bg-gray-100 block w-full text-gray-700 font-semibold rounded-lg py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                      id="grid-first-name"
                      type="number"
                      name="phone"
                      placeholder="+91 XXXXXXXXXX"
                      value={userData.phone}
                      onChange={postUserData}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-1 mb-6 md:mb-0">
                    <input
                      className="appearance-none bg-gray-100 block w-full text-gray-700 font-semibold rounded-lg py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                      id="grid-last-name"
                      type="email"
                      name="email"
                      placeholder="example123@gmail.com"
                      value={userData.email}
                      onChange={postUserData}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap mb-6">
                  <div className="w-full px-1">
                    <textarea
                      type="text"
                      name="message"
                      placeholder="Details"
                      value={userData.message}
                      onChange={postUserData}
                      rows="5"
                      className="appearance-none bg-gray-100 block w-full text-gray-700 font-semibold rounded-lg py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                    ></textarea>
                  </div>
                  <div className="mx-auto justify-between w-fit px-3 mt-10">
                    <button
                      className="shadow-md shadow-gray-500 text-[#3F3503] hover:text-blue-800/40 backdrop-blur-3xl focus:shadow-outline focus:outline-none font-bold py-2 px-6 rounded-lg"
                      type="submit"
                      onClick={submitData}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </motion.div>
            {/* End Card */}
            <motion.div
              variants={item}
              className="divide-y divide-gray-200 dark:divide-gray-800"
            >
              {/* Icon Block */}
              <div className="flex py-6">
                <svg
                  className="flex-shrink-0 size-6 mt-1.5 "
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx={12} cy={12} r={10} />
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                  <path d="M12 17h.01" />
                </svg>
                <div className="grow">
                  <h3 className="font-semibold ">Knowledgebase</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    We're here to help with any questions or code.
                  </p>
                  <a
                    className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-700 dark:hover:text-gray-900 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    href="#"
                  >
                    Contact support
                    <svg
                      className="flex-shrink-0 size-2.5 transition ease-in-out group-hover:translate-x-1"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.975821 6.92249C0.43689 6.92249 -3.50468e-07 7.34222 -3.27835e-07 7.85999C-3.05203e-07 8.37775 0.43689 8.79749 0.975821 8.79749L12.7694 8.79748L7.60447 13.7596C7.22339 14.1257 7.22339 14.7193 7.60447 15.0854C7.98555 15.4515 8.60341 15.4515 8.98449 15.0854L15.6427 8.68862C16.1191 8.23098 16.1191 7.48899 15.6427 7.03134L8.98449 0.634573C8.60341 0.268455 7.98555 0.268456 7.60447 0.634573C7.22339 1.00069 7.22339 1.59428 7.60447 1.9604L12.7694 6.92248L0.975821 6.92249Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {/* End Icon Block */}
              {/* Icon Block */}
              <div className="flex py-6">
                <svg
                  className="flex-shrink-0 size-6 mt-1.5 "
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
                  <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
                </svg>
                <div className="grow">
                  <h3 className="font-semibold ">FAQ</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Search our FAQ for answers to anything you might ask.
                  </p>
                  <a
                    className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-700 dark:hover:text-gray-900 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    href="#"
                  >
                    Visit FAQ
                    <svg
                      className="flex-shrink-0 size-2.5 transition ease-in-out group-hover:translate-x-1"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.975821 6.92249C0.43689 6.92249 -3.50468e-07 7.34222 -3.27835e-07 7.85999C-3.05203e-07 8.37775 0.43689 8.79749 0.975821 8.79749L12.7694 8.79748L7.60447 13.7596C7.22339 14.1257 7.22339 14.7193 7.60447 15.0854C7.98555 15.4515 8.60341 15.4515 8.98449 15.0854L15.6427 8.68862C16.1191 8.23098 16.1191 7.48899 15.6427 7.03134L8.98449 0.634573C8.60341 0.268455 7.98555 0.268456 7.60447 0.634573C7.22339 1.00069 7.22339 1.59428 7.60447 1.9604L12.7694 6.92248L0.975821 6.92249Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {/* End Icon Block */}
              {/* Icon Block */}
              <div className=" flex py-6">
                <svg
                  className="flex-shrink-0 size-6 mt-1.5 "
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z" />
                  <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10" />
                </svg>
                <div className="grow">
                  <h3 className="font-semibold ">Contact us by email</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    If you wish to write us an email instead please use
                  </p>
                  <a
                    href="mailto:contact@modelverse.in"
                    className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-700 dark:hover:text-gray-900 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  >
                    contact@modelverse.in
                  </a>
                </div>
              </div>
              {/* End Icon Block */}
            </motion.div>
          </div>
        </div>
      </motion.div>
      {/* End Contact Us */}
    </div>
  );
}

export default Contact;

// import React, { useState } from 'react';
// // import './firebaseConfig'; // Add this line prevent firebase not loading error
// import  '../firebaseConfig'
// import { getFirestore, addDoc, collection } from "firebase/firestore";

// function Contact() {
//   const [inputValue1, setInputValue1] = useState('');
//   const [inputValue2, setInputValue2] = useState('');

//   const db = getFirestore();

//   const saveDataToFirestore = async () => {
//       const docRef = await addDoc(collection(db, "myCollection"), {
//         field1: inputValue1,
//         field2: inputValue2,
//       });
//       alert("Document written to Database");
//   };

//   return (
//     <div className="App">
//       <h1>Save Data to Firebase Firestore</h1>
//       <input
//         type="text"
//         value={inputValue1}
//         onChange={(e) => setInputValue1(e.target.value)}
//       />
//       <input
//         type="text"
//         value={inputValue2}
//         onChange={(e) => setInputValue2(e.target.value)}
//       />
//       <button onClick={saveDataToFirestore}>Save to Firestore</button>
//     </div>
//   );
// }

// export default Contact;
