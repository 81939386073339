import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

const Privacy = () => {
    return (
        <div>
            <Link to='/' className="scroll-smooth flex-shrink-0 flex shadow-md shadow-gray-400 h-16  justify-center items-center">
                <img className="w-10 rounded-full" src="logo.jpg" alt="Logo" />
                <h2 className='font-semibold text-blue-800'>Model Verse</h2>
            </Link>
            <div className='lg:mx-40 mx-5 lg:my-20 py-5'>
                <h1 className='text-5xl underline font-semibold mb-10 tracking-wider'>Privacy Policy</h1>
                <p className='text-justify tracking-wide'>
                    Privacy Policy for ModelVerse

                    At ModelVerse, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our website or services.

                    Information We Collect:

                    Personal Information: When you use our website or services, we may collect personal information such as your name, email address, and contact details.
                    Uploaded Images: When you upload images of garments or models to our platform, we may collect and process these images to create AI fashion models or generate new images.
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Usage Data:
                    </h1>
                    We may collect information about your interaction with our website or services, including the pages visited, the duration of your visit, and other usage statistics.
                    How We Use Your Information:

                    To Provide Services: We use the information collected to provide you with the requested services, including creating AI fashion models based on the uploaded images.
                    Improving Our Services: We may analyze usage data to improve our website and services, enhance user experience, and develop new features.
                    Communication: We may use your contact information to communicate with you about your account, updates, promotional offers, or important notices.
                    Legal Compliance: We may use your information to comply with legal obligations or enforce our Terms of Service.
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Data Security:
                    </h1>
                    We implement appropriate technical and organizational measures to safeguard your personal information against unauthorized access, alteration, disclosure, or destruction.
                    We restrict access to your personal information to authorized personnel only and ensure that they are bound by confidentiality obligations.
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Third-Party Services:
                    </h1>


                    We may use third-party services and tools to analyze website traffic, track user behavior, or provide additional functionalities. These third-party services may collect information about your usage of our website, subject to their respective privacy policies.
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Data Retention:
                    </h1>

                    We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. When no longer needed, we securely delete or anonymize your data.
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Your Rights:
                    </h1>

                    You have the right to access, update, or delete your personal information. You may also request to restrict or object to the processing of your data.
                    You can exercise these rights by contacting us using the information provided below.
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Changes to Privacy Policy:
                    </h1>

                    We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website. We encourage you to review this Privacy Policy periodically for updates.
                    <h1 className='text-2xl font-semibold underline my-5'>
                        Contact Us:
                    </h1>

                    If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at [contact@modelverse.in].
                    By using our website or services, you consent to the collection and use of your information as described in this Privacy Policy.
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default Privacy
