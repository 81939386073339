import React, { useState } from 'react';

const Accordion = ({ items }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleItem = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="w-[100%] mx-auto scroll-smooth">
            {items.map((item, index) => (
                <div key={index} className=" rounded-xl mb-2 border-2 border-[#DCDCDC]">
                    <div
                        className="flex justify-between items-center p-4 cursor-pointer"
                        onClick={() => toggleItem(index)}
                    >
                        <h1 className="text-gray-900 2xl:text-xl xl:text-lg text-sm text-left title-font font-semibold">{item.title}</h1>
                        <span className='2xl:text-3xl xl:text-2xl'>{openIndex === index ? '-' : '+'}</span>
                    </div>
                    {openIndex === index && (
                        <div className="p-4 border-t text-justify border-gray-300">
                            <p>{item.content}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Accordion;
