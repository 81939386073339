import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Header() {
  const { ref, inView } = useInView({ triggerOnce: true });

  const variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const variantss = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      y: -50,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        // duration: 0.1,
      },
    },
  };
  const items = {
    hidden: {
      opacity: 0,
      y: -50,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
      },
    },
  };

  const [isOpen, setIsOpen] = useState(false);

  const links = [
    {
      nav: "How it Works",
      link: "#howitworks",
    },
    {
      nav: "Styles",
      link: "#gallery",
    },
    {
      nav: "Our Gallery",
      link: "/gallery",
    },
    {
      nav: "Pricing",
      link: "#pricing",
    },
  ];

  return (
    <motion.nav
      ref={ref}
      variants={variants}
      initial="hidden"
      animate="show"
      className="scroll-smooth"
    >
      <div className="w-full mx-auto px-2 sm:px-6 lg:px-8 shadow-md shadow-gray-400">
        <div className="flex items-center justify-between h-16">
          <motion.div className="flex items-center w-full">
            <motion.a
              variants={item}
              href="/"
              className="flex-shrink-0 xl:w-fit w-full flex items-center"
            >
              <motion.img
                initial={{ rotate: 0 }}
                animate={{ rotate: 360 }}
                transition={{ duration: 0.7 }}
                className="w-10 rounded-full"
                src="logo.jpg"
                alt="Logo"
              />
              <h2 className="font-semibold text-blue-800 text-center xl:mx-0 mx-auto">
                Model Verse
              </h2>
            </motion.a>
            <div className="hidden md:block w-fit ml-auto">
              <motion.div className="children ml- flex items-center w-full mx-auto space-x-8">
                <motion.a
                  variants={item}
                  href="#how-it-works"
                  className="text-[#222121] hover:bg-neutral-800 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  How it Works
                </motion.a>
                <motion.a
                  variants={item}
                  href="#styles"
                  className="text-[#222121] hover:bg-neutral-800 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Styles
                </motion.a>
                <motion.a
                  variants={item}
                  href="#plans"
                  className="text-[#222121] hover:bg-neutral-800 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Plans
                </motion.a>
                {/* <motion.a variants={item} href='/gallery' className="text-[#222121] hover:bg-neutral-800 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Our Gallery</motion.a> */}
              </motion.div>
            </div>
            <div className="hidden md:block">
              {/* <motion.a variants={item} href='#contact' className="cursor-pointer transition-all bg-blue-50 hover:bg-blue-100 text-black px-6 py-2 rounded-lg border-black border-2 mx-1 hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px]">
                                Login
                            </motion.a> */}
              <motion.a
                variants={item}
                href="#contact"
                className="cursor-pointer transition-all bg-blue-500 text-white px-6 py-2 rounded-lg border-blue-600 mx-1 border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px]"
              >
                Contact Sales
              </motion.a>
            </div>
          </motion.div>
          <motion.div variants={item} className="-mr-2 flex md:hidden w-10">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`${
                isOpen ? "hidden" : "block"
              } inline-flex items-center justify-center p-2 rounded-md text-[#537FEF] hover:text-[#F5F8FF] bg-[#F5F8FF] hover:bg-[#537FEF] focus:outline-none focus:bg-[#537FEF] focus:text-[#F5F8FF]`}
            >
              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </motion.div>
        </div>
      </div>
      <div className={`${isOpen ? "" : "hidden"} md:hidden`}>
        <div
          className="w-10 absolute top-0 right-0 py-[3%] transition-all ease-in-out duration-1000 "
          onClick={() => setIsOpen(false)}
        >
          <svg
            className="h-9 w-9 text-[#537FEF] transition-all ease-in-out duration-1000"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </div>
        <motion.div
          ref={ref}
          variants={variantss}
          initial="hidden"
          animate="show"
          className="px-2 pt-2 pb-3 sm:px-3 w-fit h-screen text-left"
        >
          <motion.a
            variants={items}
            href="#how-it-works"
            className="w-fit text-[#222121] hover:bg-neutral-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium mt-10"
          >
            How it Works
          </motion.a>
          <motion.a
            variants={items}
            href="#styles"
            className="w-fit text-[#222121] hover:bg-neutral-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium mt-10"
          >
            Styles
          </motion.a>
          <motion.a
            variants={items}
            href="#plans"
            className="w-fit text-[#222121] hover:bg-neutral-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium mt-10"
          >
            Plans
          </motion.a>
          {/* <motion.a
                        variants={items}
                        href="/gallery" className="w-fit text-[#222121] hover:bg-neutral-800 hover:text-white block px-3 py-2 rounded-md text-base font-medium mt-10">Our Gallery</motion.a> */}
          <div className="mt-10">
            {/* <a href='#' className="mt-10 w-fit cursor-pointer transition-all bg-blue-50 hover:bg-blue-100 text-black px-6 py-2 rounded-lg border-black border-2 mx-1 hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px]">
                            Login
                        </a> */}
            <a
              href="http://platform.modelverse.in/signup"
              className="cursor-pointer transition-all bg-blue-500 text-white px-6 py-2 rounded-lg border-blue-600 mx-1 border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px]"
            >
              Contact Sales
            </a>
          </div>
        </motion.div>
      </div>
    </motion.nav>
  );
}
