import React from 'react'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Poster = () => {

    const { ref, inView } = useInView({ triggerOnce: true });

    const variants = {
        hidden: { opacity: 0 },
        show: {
            opacity: inView ? 1 : 0,
            transition: {
                staggerChildren: 0.4,
            },
        },
    };

    const item = {
        hidden: {
            opacity: 0,
            y: 100,
        },
        show: {
            opacity: 1,
            y: inView ? 0 : 100,
            transition: {
                duration: 0.8,
            },
        },
    };

    return (
        <div>
            <motion.section
                ref={ref}
                variants={variants}
                initial='hidden'
                animate='show'
                className="scroll-smooth py-10 body-font bg-gradient-to-r from-[#29B5D9BF] via-[#8386FF]  to-[#9F6FFF] xl:mx-20 lg:h-full rounded-3xl">
                <div className="container mx-auto flex xl:px-20 xl:my-24 my-10 md:flex-row flex-col items-center">
                    <motion.div
                        variants={item}
                        className="mx- items-center text-center">
                        <h1 className="title-font 2xl:text-5xl xl:text-5xl text-3xl xl:my-5 xl:px-0 px-10 my-10 mb-4 font-bold text-white">11,112 AI Models already generated for 66+ happy customers!</h1>
                        <motion.div
                            variants={item}
                            className="flex w-full my-10 justify-center items-end">

                            <a href='#contact' className="xl:w-[30%] w-[50%] flex items-center mt-auto bg-white text-black hover:font-semibold py-2 px-5 focus:outline-none rounded-xl capitalize">Get your images
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </a>
                        </motion.div>
                    </motion.div>
                </div>
            </motion.section>
        </div>
    )
}

export default Poster
