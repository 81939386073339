import React from 'react'
import Accordion from './Accordion'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const QNA = () => {
    const { ref, inView } = useInView({ triggerOnce: true });

    const variants = {
        hidden: { opacity: 0 },
        show: {
            opacity: inView ? 1 : 0,
            transition: {
                staggerChildren: 0.2,
            },
        },
    };

    const item = {
        hidden: {
            opacity: 0,
            y: 100,
        },
        show: {
            opacity: 1,
            y: inView ? 0 : 100,
            transition: {
                duration: 0.8,
            },
        },
    };

    const variants2 = {
        hidden: { opacity: 0 },
        show: {
            opacity: inView ? 1 : 0,
            transition: {
                staggerChildren: 0.2,
            },
        },
    };

    const items2 = {
        hidden: {
            opacity: 0,
            y: 100,
        },
        show: {
            opacity: 1,
            y: inView ? 0 : 100,
            transition: {
                duration: 1,
            },
        },
    };

    const questions = [
        {
            'title': 'What happens to my data after?',
            'content': 'We will automatically delete the data provided by you  from our servers/GPU APIs within 30 days. However, you can also delete them instantly by pressing the "Delete" button.'
        },
        {
            'title': 'Where do you store my data?',
            'content': 'We take the security of your data very seriously. We store it on servers in the India that are managed by our highly secure third-party partners.'
        },
        {
            'title': 'Can I get my data deleted instantly?',
            'content': 'We will delete your photos and data from our databases within 30 days. You can also request us to delete them instantly by emailing us'
        },
        {
            'title': 'Is my payment secured?',
            'content': 'Currently we accept payments through stripe. In case, if you want to pay through UPI, please write to us. None of your credit/debit card information gets stored with us '
        },
        {
            'title': 'Can I ask for refund?',
            'content': 'The refund of purchases can be made within 14 days, provided we haven’t processed your images..'
        },
        {
            'title': 'Where can I use my Model Images?',
            'content': 'After purchasing the service, we deliver you full commercial license and ownership of the on-model images. You can use it anywhere you want to.'
        },
    ]

    return (
        <motion.div
            ref={ref}
            variants={variants}
            initial='hidden'
            animate='show'
            className='scroll-smooth bg-gradient-to-r from-[#CBFFFD69] via-[#A5FFFC69] via-[#D3C8FF78] to-[#EFFFFC]'>
            <section className=" body-font">
                <div className="container px-10 xl:py-24 py-10 mx-auto ">
                    <div className=" w-full mb-0 xl:mb-0 xl:px-10 rounded-lg overflow-hidden">
                        <motion.h1
                            variants={item}
                            className='2xl:text-6xl xl:text-5xl text-3xl font-bold text-center text-[#222121] '>Frequently Asked Questions</motion.h1>
                        <motion.p
                            variants={item}
                            className='2xl:w-[40%] xl:w-[50%] mx-auto my-10 leading-relaxed 2xl:text-xl xl:text-lg xl:px-10 px-2 text-black'>Answers to some commonly asked questions. Incase if you still have any query, feel free tow rite us at </motion.p>
                    </div>
                    <div className="xl:py-0 xl:w-[60%] mx-auto xl:text-left text-center">
                        <motion.div
                            variants={item}
                            className="w-full mx-auto flex flex-col mb-10 xl:items-start items-center">
                            <Accordion
                                items={questions} />
                        </motion.div>
                    </div>
                </div>
            </section>
        </motion.div>
    )
}

export default QNA
