import "./App.css";
import Home from "./pages/Home";
import TOS from "./pages/TOS";
import Refund from "./pages/Refund";
import Privacy from "./pages/Privacy";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "./components/Loader";
// import MainGallery from './pages/MainGallery';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const images = document.querySelectorAll("img");
    let loadedCount = 0;

    const onLoad = () => {
      loadedCount++;
      if (loadedCount === images.length) {
        setLoading(false);
      }
    };

    images.forEach((image) => {
      if (image.complete) {
        onLoad();
      } else {
        image.addEventListener("load", onLoad);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener("load", onLoad);
      });
    };
  }, []);

  return (
    <div className="App scroll-smooth ">
      <BrowserRouter>
        {loading ? (
          <Loader />
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path='/gallery' element={<MainGallery />} /> */}
            <Route path="/terms-of-service" element={<TOS />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/privacy-policy" element={<Privacy />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
