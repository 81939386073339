import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Header from "../components/Header";
import Tools from "./Tools";
import HowItWorks from "../components/HowItWorks";
import Gallery from "../components/Gallery";
import Testimonials from "../components/Testimonials";
import Pricing from "../components/Pricing";
import QNA from "../components/QNA";
import Poster from "../components/Poster";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import ImageCarousel from "../components/ImageCarousel";
import { ToastContainer } from "react-toastify";

const Home = () => {
  return (
    <div className="scroll-smooth">
      <div>
        <Header />
        <Banner />
      </div>

      <div id="how-it-works">
        <HowItWorks />
      </div>

      <div id="styles">
        <ImageCarousel />
      </div>

      {/* <div id='gallery'>
                <Gallery />
            </div> */}

      {/* <div id='testimonials'>
                <Testimonials />
            </div> */}

      <div id="plans">
        <Pricing />
      </div>

      <div id="frequently-asked-questions">
        <QNA />
      </div>

      <div id="contact">
        <Contact />
      </div>

      <div id="poster">
        <Poster />
      </div>

      <div>
        <Footer />
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Home;
