import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const HowItWorks = () => {
  const { ref, inView } = useInView({ triggerOnce: true });

  const variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: inView ? 1 : 0,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    show: {
      opacity: 1,
      y: inView ? 0 : 100,
      transition: {
        duration: 0.5,
      },
    },
  };

  const variants2 = {
    hidden: { opacity: 0 },
    show: {
      opacity: inView ? 1 : 0,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const items2 = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    show: {
      opacity: 1,
      y: inView ? 0 : 100,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate="show"
      className="bg-[#F0F4FF] xl:py-24 py-14 scroll-smooth"
    >
      <motion.h1
        variants={item}
        className="2xl:text-5xl xl:text-4xl text-3xl text-black font-semibold"
      >
        How It Works
      </motion.h1>
      <section className="relative overflow-hidden">
        <div className="mt-2 md:mt-0 pb-6 overflow-hidden">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative">
            <div className="relative mt-12 lg:mt-20">
              <div className="absolute inset-x-0 hidden 2xl:px-24 xl:px-40 top-2 md:block md:px-20 lg:px-28">
                <hr className="h-px my-5 border-2 border-gray-500 " />
              </div>
              <div className="relative grid grid-cols-1 text-center gap-y-8 sm:gap-y-10 md:gap-y-12 md:grid-cols-4 xl:gap-x-12 2xl:gap-x-48">
                <motion.div variants={item}>
                  <div className="flex items-center justify-center 2xl:w-20 xl:w-16 w-14 2xl:h-20 xl:h-16 h-14 mx-auto bg-blue-500 rounded-full shadow">
                    <span className="2xl:text-2xl xl:text-xl text-xl font-semibold text-white">
                      1
                    </span>
                  </div>
                  <h3 className="mt-4 sm:mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                    Pick a style
                  </h3>
                </motion.div>
                <motion.div variants={item}>
                  <div className="flex items-center justify-center 2xl:w-20 xl:w-16 w-14 2xl:h-20 xl:h-16 h-14 mx-auto bg-blue-500 rounded-full shadow">
                    <span className="2xl:text-2xl xl:text-xl text-xl font-semibold text-gray-700 dark:text-gray-200">
                      2
                    </span>
                  </div>
                  <h3 className="mt-4 sm:mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                    Upload your image
                  </h3>
                </motion.div>
                <motion.div variants={item}>
                  <div className="flex items-center justify-center 2xl:w-20 xl:w-16 w-14 2xl:h-20 xl:h-16 h-14 mx-auto bg-blue-500 rounded-full shadow">
                    <span className="2xl:text-2xl xl:text-xl text-xl font-semibold text-gray-700 dark:text-gray-200">
                      3
                    </span>
                  </div>
                  <h3 className="mt-4 sm:mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                    Ai-photographer does the photoshoot
                  </h3>
                </motion.div>
                <motion.div variants={item}>
                  <div className="flex items-center justify-center 2xl:w-20 xl:w-16 w-14 2xl:h-20 xl:h-16 h-14 mx-auto bg-blue-500 rounded-full shadow">
                    <span className="2xl:text-2xl xl:text-xl text-xl font-semibold text-gray-700 dark:text-gray-200">
                      4
                    </span>
                  </div>
                  <h3 className="mt-4 sm:mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                    Download your favorites
                  </h3>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font xl:mx-10">
        <div className="container mx-auto flex px-5 py- md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center space-y-5">
            <div className="space-y-2 text-left">
              <motion.h1
                variants={item}
                className="title-font 2xl:text-5xl xl:text-4xl text-2xl font-semibold text-gray-900 tracking-wider capitalize"
              >
                The quickest and simplest
              </motion.h1>
              <motion.h1
                variants={item}
                className="title-font 2xl:text-5xl xl:text-4xl text-2xl font-semibold text-gray-900 tracking-wider capitalize"
              >
                way to get a professional
              </motion.h1>
              <motion.h1
                variants={item}
                className="title-font 2xl:text-5xl xl:text-4xl text-2xl font-semibold text-gray-900 tracking-wider capitalize"
              >
                model Photoshoot
              </motion.h1>
            </div>
            <div>
              <ul className="2xl:text-lg xl:text-base list-disc list-inside font-normal text-gray-800 leading-relaxed text-left">
                <motion.li variants={item}>
                  Can’t be distinguished from real photos
                </motion.li>
                <motion.li variants={item}>
                  No need for physical shoots
                </motion.li>
                <motion.li variants={item}>
                  Different Location, Different Model, Same Garment, Anytime,
                  Anywhere
                </motion.li>
              </ul>
            </div>
            {/* <motion.div
                            variants={item}
                            className="flex justify-center">
                            <button className="inline-flex text-gray-800 border rounded-xl border-black 2xl:py-4 xl:py-2 py-3 px-6 focus:outline-none hover:bg-gray-200 2xl:text-xl xl:text-lg">Watch Video</button>
                        </motion.div> */}
          </div>
          <motion.div
            variants={variants2}
            initial="hidden"
            animate="show"
            className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6"
          >
            <motion.img
              variants={items2}
              className="object-cover object-center rounded"
              alt="hero"
              src="howitworks.png"
            />
          </motion.div>
        </div>
      </section>
    </motion.div>
  );
};

export default HowItWorks;
