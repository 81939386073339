import React from 'react'
import styled, { keyframes, css } from "styled-components";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ImageCarousel = () => {

  const { ref, inView } = useInView({ triggerOnce: true });

  const variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: inView ? 1 : 0,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    show: {
      opacity: 1,
      y: inView ? 0 : 100,
      transition: {
        duration: 0.8,
      },
    },
  };

  const variants2 = {
    hidden: { opacity: 0 },
    show: {
      opacity: inView ? 1 : 0,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const items2 = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    show: {
      opacity: 1,
      y: inView ? 0 : 100,
      transition: {
        duration: 1,
      },
    },
  };

  const row1 = [
    "ModelsDone/Model (21).png",
    "ModelsDone/Model (22).png",
    "ModelsDone/Model (23).png",
    "ModelsDone/Model (24).png",
    "ModelsDone/Model (25).png",
    "ModelsDone/Model (26).png",
    "ModelsDone/Model (27).png",

  ];
  const row2 = [
    "ModelsDone/Model (1).png",
    "ModelsDone/Model (2).png",
    "ModelsDone/Model (3).png",
    "ModelsDone/Model (4).png",
    "ModelsDone/Model (5).png",
    "ModelsDone/Model (6).png",
    "ModelsDone/Model (7).png",

  ];
  return (
    <div className='xl:py-24 py-14 bg-black/85 scroll-smooth'>

      {/* Image Carousel */}
      <motion.div
        ref={ref}
        variants={variants}
        initial='hidden'
        animate='show'
        className='xl:w-[50%] 2xl:w-[50%] mx-auto 2xl:text-5xl xl:text-4xl text-2xl font-semibold space-y-5'>
        <motion.h1
          variants={item}
          className='text-white'>
          Multi Ethnic
          <motion.span
            variants={item}
            className='leading-tight bg-gradient-to-r from-[#29B5D9BF] via-[#8386FF] to-[#B48FFF] bg-clip-text text-transparent'>
            &nbsp;AI Fashion Model </motion.span>
        </motion.h1>
      </motion.div>
      <motion.div
        variants={item}
      >
        <AppContainer className='xl:h-[40vh] h-[20vh]'>
          <Wrapper className='Wrapper'>
            <Marquee className='Marquee'>
              <MarqueeGroup className='MarqueeGroup'>
                {row1.map((el) => (
                  <ImageGroup className='ImageGroup'>
                    <Image src={el} className='rounded-2xl' />
                  </ImageGroup>
                ))}
              </MarqueeGroup>
              <MarqueeGroup className='MarqueeGroup'>
                {row2.map((el1) => (
                  <ImageGroup className='ImageGroup'>
                    <Image src={el1} className='rounded-2xl' />
                  </ImageGroup>
                ))}
              </MarqueeGroup>
            </Marquee>
          </Wrapper>
        </AppContainer>
      </motion.div>
    </div>
  )
}

export default ImageCarousel

const AppContainer = styled.div`
  width: 98vw;
  height: ;
  color: #000000;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Marquee = styled.div`
  display: flex;
  overflow: hidden;
  user-select: none;

  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 20s linear infinite;  
`;

const style = {
  '@media (max-width: 600px)': {
    width: '100%',
    justify: 'space-evenly',
  },
}

const MarqueeGroup = styled.div`
  ${common}
  ${style}
`;

const ImageGroup = styled.div`
  display: ;
  place-items: ;
`;

const ResponsiveImage = {
  '@media (max-width: 600px)': {
    width: '90vw',
    height: '10vh',
    // padding: '0 0'
  },
}

const Image1 = {
  width: '10vw',
  height: '30vh',
  padding: '0 '
}

const Image = styled.img`
  ${Image1}
  ${ResponsiveImage}
`;