// Loader.js
import React from 'react';
import Typewriter from 'typewriter-effect';

const Loader = () => {
    return (
        <div className="scroll-smooth font-bold font-serif lg:text-3xl text-xl fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
            <div className='bg-gradient-to-tr from-[#29B5D9BF] via-[#8386FF] to-[#9F6FFF] lg:m-10 m-5 animate-spin rounded-full'>
                <img src='logo.jpg' className="rounded-full h-28 w-28"></img>
            </div>
            <Typewriter
                options={{
                    autoStart: true,
                    loop: true,
                    delay: 40,
                    strings: [
                        "Model Verse..."
                    ],
                }} />
        </div>
    );
};

export default Loader;
