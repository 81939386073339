import React from "react";
import { motion } from "framer-motion";

const Navbar = () => {
  return (
    <div>
      <nav className="scroll-smooth relative flex items-center justify-between md:justify-center py-6 px-4 xl:mx-10 bg-white rounded-2xl border-b-4 border-gray- shadow-md shadow-gray-300">
        <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
          <div className="flex items-center justify-between w-full md:w-auto">
            <a className="flex items-center" href="" aria-label="Home">
              <img
                className="rounded-full"
                src="logo.jpg"
                height="40"
                width="40"
              />
              <h2 className="font-bold xl:text-2xl text-lg">Model Verse</h2>
            </a>
            <div className="-mr-2 flex items-center md:hidden">
              <button
                type="button"
                id="main-menu"
                aria-label="Main menu"
                aria-haspopup="true"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="hidden md:flex md:space-x-10 text-base">
          <a
            href="#features"
            className="font-semibold text-gray-800 hover:text-gray-900 transition duration-150 ease-in-out"
          >
            Home
          </a>
          <a
            href="#pricing"
            className="font-semibold text-gray-800 hover:text-gray-900 transition duration-150 ease-in-out"
          >
            Services
          </a>
          <a
            href="/blog"
            className="font-semibold text-gray-800 hover:text-gray-900 transition duration-150 ease-in-out"
          >
            Contact
          </a>
        </div>
        <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
          <span className="inline-flex">
            <a
              href="/login"
              className="inline-flex items-center px-4 py-2 border-2 border-cyan-500 hover:bg-cyan-500 rounded-xl text-base leading-6 font-semibold text-gray-800 hover:text-white focus:outline-none transition duration-150 ease-in-out"
            >
              Login
            </a>
          </span>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
