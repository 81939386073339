import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Banner = () => {
  const { ref, inView } = useInView({ triggerOnce: true });

  const variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      x: -100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const variants2 = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const items2 = {
    hidden: {
      opacity: 0,
      x: 100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <motion.div
      ref={ref}
      className="scroll-smooth xl:py-0 py-5  bg-gradient-to-r from-[#CBFFFD69] via-[#A5FFFC69] via-[#D3C8FF78] to-[#EFFFFC]"
    >
      <p className="text-white bg-gradient-to-r from-black via-violet-500 to-cyan-500 my-2 shadow-sm shadow-black font-semibold">
        We are revamping our website. Therefore, some of the
        features may not work.
      </p>
      <div className=" w-fit lg:mx-8 mx-auto py-2 tracking-wider font-serif font-semibold">
        <div className=" w-fit space-y-2">
          <h1 className="px-5 uppercase bg-neutral-900 text-white rounded-3xl">
            As Seen On
          </h1>

          <img className="w-24 mx-auto rounded-xl" src="sharktanklogo.jpg" />
        </div>
      </div>
      <motion.div
        ref={ref}
        variants={variants}
        initial="hidden"
        animate="show"
        className=" xl:flex  xl:px-[5%] px-5"
      >
        <div className="">
          <motion.div
            // ref={ref}
            // variants={variants}
            // initial='hidden'
            // animate='show'
            className="2xl:text-6xl xl:text-5xl text-3xl text-left font-bold xl:space-y-8 space-y-4"
          >
            <motion.h1 variants={item}>Professional Model</motion.h1>
            <motion.h1 variants={item}>Catalogues,</motion.h1>
            <motion.h1 variants={item}>Without Physical Studio</motion.h1>
            <motion.h1 variants={item}>Photoshoot</motion.h1>
          </motion.div>
          <div className="text-left 2xl:mt-10 lg:mt-10">
            <ul className="list-disc list-inside font-normal 2xl:text-xl xl:text-lg">
              <motion.li variants={item}>HD Quality Images</motion.li>
              <motion.li variants={item}>
                30 + Unique Location & Models
              </motion.li>
              <motion.li variants={item}>
                Wide Variety Of Locations & Skin Tones
              </motion.li>
            </ul>
          </div>
          <motion.div
            variants={item}
            className="xl:w-fit xl:mx-20 xl:mt-20 mt-10"
          >
            <a
              href="#contact"
              className="2xl:text-lg xl:text-base cursor-pointer transition-all bg-blue-500 text-white px-6 py-2 rounded-lg border-blue-600 border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px]"
            >
              Get 2 Free Images
            </a>
          </motion.div>
        </div>

        <motion.div
          ref={ref}
          variants={variants}
          initial="hidden"
          animate="show"
          className="xl:w-1/2 w-full ml-auto xl:h-screen"
        >
          <div className="mx-auto w-full ">
            <div className="flex lg:my-0 my-10">
              <div className="lg:mx-5">
                <motion.div
                  variants={items2}
                  className="w-[45vw] h-[35vh] lg:w-full lg:h-[70vh] my-20 block rounded-3xl mx-auto"
                >
                  <img
                    className="rounded-3xl 2xl:w-full xl:w-full 2xl:h-full xl:h-full h-full"
                    src="models/banner(1).jpg"
                    alt="BannerImage1"
                  />
                </motion.div>
              </div>

              <div className="p-1">
                <motion.div
                  variants={items2}
                  className="w-[45vw] h-[20vh] lg:w-[20vw] lg:h-[30vh] block rounded-3xl ml-auto"
                >
                  <img
                    className="rounded-3xl w-full h-full object-cover"
                    src="models/banner(3).jpg"
                    alt="BannerImage3"
                  />
                </motion.div>

                <motion.div
                  variants={items2}
                  className="w-[45vw] h-[35vh] lg:w-[20vw] lg:h-[60vh] block rounded-3xl mx-auto lg:mt-4 my-1"
                >
                  <img
                    className="rounded-3xl 2xl:w-full xl:w-full 2xl:h-full xl:h-full h-full"
                    src="models/banner(2).jpg"
                    alt="BannerImage4"
                  />
                </motion.div>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Banner;
