import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
// import { Link } from 'react-router-dom'

function Footer() {
  const [userData, setuserData] = useState({
    getUpdate: "",
  });

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setuserData({ ...userData, [name]: value });
  };

  // connect with Database
  const submitData = async (event) => {
    event.preventDefault();
    const { getUpdate } = userData;

    if (getUpdate) {
      const res = await fetch(
        "https://model-verse-db-default-rtdb.firebaseio.com/getUpdateRecords.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            getUpdate,
          }),
        }
      );

      if (res) {
        setuserData({
          getUpdate: "",
        });
        // alert("Data Stored");
        // toast("Wow so easy !");
        toast.success("Done! Our team will contact you soon.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // toast.success("Our team will contact you soon");
      } else {
        toast.error("Invalid Credentials", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      // alert("Unfilled");
      toast.error("Invalid Credentials", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const links = [
    {
      nav: "How it Works",
      link: "",
    },
    {
      nav: "Contact Us",
      link: "",
    },
    {
      nav: "Get Started",
      link: "",
    },
    {
      nav: "Examples",
      link: "",
    },
    {
      nav: "FAQs",
      link: "",
    },
  ];
  return (
    <div className="scroll-smooth">
      <hr className="h-px my-4 border border-gray-300" />
      <footer className="body-font py-5 px-10">
        <div className="container lg:px-10 xl:py-5 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-col">
          <div className="lg:w-1/2 w-full md:mx-0 text-center md:text-left">
            <div className="flex-shrink-0 flex items-center xl:mx-0 mx-auto w-fit">
              <img className="w-10 rounded-full" src="logo.jpg" alt="Logo" />
              <h2 className="font-semibold text-blue-800">Model Verse</h2>
            </div>
            <p className="lg:w-[60%] mt-2 text-xs xl:mx-0 mx-auto text-black text-center lg:text-justify">
              *All the images are generated using Model Verse's platform. In
              case of any question or concerns. Reach out to us at <br />
              <a href="mailto:contact@modelverse.in">contact@modelverse.in</a>
            </p>
          </div>

          <div className="xl:w-1/2 flex-grow flex flex-wrap md:px-24 -mb-10 lg:py-0 py-5 md:mt-0 mt-0 md:text-left text-center">
            <div className="lg:w-fit md:w-1/2 w-full lg:px-4 px-0 lg:ml-auto lg:my-0 my-5">
              <h2 className="w-full title-font font-bold text-black tracking-widest 2xl:text-2xl xl:text-xl text-lg mb-3">
                Get Updated
              </h2>

              <form method="POST" className="list-none mb-10">
                <li className="lg:mx-0 mx-auto xl:flex space-x-2 space-y-2">
                  <input
                    type="email"
                    name="getUpdate"
                    className="text-center inline-flex text-gray-800 border rounded-xl border-black 2xl:py-2 xl:py-2 py-2 px-6 focus:outline-none hover:bg-gray-200 2xl:text-xl xl:text-lg"
                    placeholder="Enter Your Email."
                    value={userData.getUpdate}
                    onChange={postUserData}
                  />

                  <button
                    className=" inline-flex bg-[#537FEF] text-white border rounded-xl border-black 2xl:py-4 xl:py-2 px-6 py-2 focus:outline-none hover:bg-gray-200 2xl:text-xl xl:text-lg"
                    type="submit"
                    onClick={submitData}
                  >
                    Subscribe
                  </button>
                </li>
                <li>
                  <a
                    href="mailto:contact@modelverse.in"
                    className=" text-xs text-[#6D6D6D] underline hover:text-gray-800"
                  >
                    subscribing you agree to with our Privacy Policy
                  </a>
                </li>
              </form>
            </div>
          </div>
        </div>
        <hr className="mx-10 my-4 border border-gray-300" />

        <div>
          <div className="container items-center mx-auto px-5 flex flex-wrap flex-col sm:flex-row">
            <div className="space-x-2">
              <Link
                to="/privacy-policy"
                className="text-black text-xs text-center sm:text-left underline tracking-wider"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-of-service"
                className="text-black text-xs text-center sm:text-left underline tracking-wider"
              >
                Terms Of Service
              </Link>
              <Link
                to="/refund"
                className="text-black text-xs text-center sm:text-left underline tracking-wider"
              >
                Refund
              </Link>
            </div>
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
              <h2 className="text-black text-xs text-center sm:text-left tracking-wider">
                © 2024 Modelverse. All rights reserved.
              </h2>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
